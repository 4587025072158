// Foundation Start
$(document).foundation();

gsap.registerPlugin(MorphSVGPlugin);

var tl = gsap.timeline({repeat: 5});

tl.to('#lego', {duration: 0.5, morphSVG: {shape: "#umbrella", map: "complexity"}}, "+=0.5")
  .to('#lego', {duration: 0.5, morphSVG: {shape: "#starfish", map: "complexity"}}, "+=0.5")
  .to('#lego', {duration: 0.5, morphSVG: {shape: "#basketball", map: "complexity"}}, "+=0.5")
  .to('#lego', {duration: 0.5, morphSVG: {shape: '#lego', map: "complexity"}}, "+=0.5");

// // Cookies Scripts
// function setCookie(cname, cvalue, exdays) {
//     const d = new Date();
//     d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
//     var expires = "expires="+d.toGMTString();
//     document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
//   }

// function getCookie(cname) {
//     var name = cname + "=";
//     var decodedCookie = decodeURIComponent(document.cookie);
//     var ca = decodedCookie.split(';');
//     for(var i = 0; i < ca.length; i++) {
//         var c = ca[i];
//         while (c.charAt(0) == ' ') {
//             c = c.substring(1);
//         }
//         if (c.indexOf(name) == 0) {
//             return c.substring(name.length, c.length);
//         }
//     }
//     return "";
// }

// function checkCookie() {
//     var loader = getCookie("loaderShown");
//     if (loader == "") {
//         setCookie("loaderShown", "yes", 0.0416667);
//     } else {
//         paceOptions = {
//             startOnPageLoad : false
//         }
//         window.paceOptions = paceOptions;
//     }
// }

// On Ready Function
$(function() {
    // Menu Toggler
    $('.header__toggler').on('click', function() {
        $('body').toggleClass('menuOpened');
    });

    // Menu Links Hover
    $('.menu__nav li>a').on({
        mouseenter: function () {
            this.parentElement.classList.add('hovered');
        },
        mouseleave: function () {
            this.parentElement.classList.remove('hovered');
        }
    });
});